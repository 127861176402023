<template>
  <b-container fluid="true">
    <b-card class="mx-auto mt-5" style="max-width: 40rem">
      <b-card-body title="Log in">
        <div class="text-center">
          <b-img width="150" class="mb-2" :src="require('@/assets/images/logo/gytp.png')" alt="logo" />
        </div>
        <el-alert
          class="my-3"
          title="If this is your first time logging in, the password you enter will be the password for your next login."
          type="warning"
          :closable="false"
        />
        <el-form ref="form" :model="form" :rules="rules" label-position="top">
          <el-form-item label="Passport" prop="passport">
            <el-input v-model="form.passport" type="text" autocomplete="off" />
          </el-form-item>
          <el-form-item label="Password" prop="password">
            <el-input v-model="form.password" type="password" autocomplete="off" />
          </el-form-item>
          <el-form-item class="text-center">
            <b-button class="mt-3" variant="primary" block @click="submit('form')">Log in</b-button>
          </el-form-item>
        </el-form>
        <p class="text-silver">If you have any questions, please consult your course group admin or teacher.</p>
      </b-card-body>
    </b-card>
  </b-container>
</template>
<script>
import '../assets/css/base.css'

export default {
  data() {
    return {
      form: {
        passport: '',
        password: ''
      },
      btnClicked: false,
      rules: {
        passport: [
          { required: true, message: 'Please input passport', trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Please input password', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    statePassport() {
      const res = null
      if (this.btnClicked) {
        return this.form.passport.length !== 0
      }
      return res
    },
    statePassword() {
      const res = null
      if (this.btnClicked) {
        return this.form.password.length !== 0
      }
      return res
    }
  },
  methods: {
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$loading({
            text: 'Login in'
          })
          this.$store.dispatch('user/login', this.form).then(e => {
            this.$loading({ text: 'Login in' }).close()
            this.$message({
              message: 'Login successful',
              type: 'success'
            })
            this.$router.push('/exam')
          }).catch(err => {
            this.$message({
              message: err.msg,
              type: 'error'
            })
            this.$loading({ text: 'Login in' }).close()
          })
        } else {
          console.log('error submit!')
          return false
        }
      })
    }
  }
}
</script>
<style scoped>

</style>
